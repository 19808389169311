<template>
  <div>
      <payroll-header>
          <template v-slot:payroll-title>
              <h3 class=" primary--text ml-2">Operations Payroll</h3>
          </template>
          <template v-slot:action-buttons>
              <AproveAuthorizeGenerate></AproveAuthorizeGenerate>
          </template>
      </payroll-header>
      <tabs-wrapper>    
      </tabs-wrapper>
  </div>
</template>

<script>
import payrollMixin from "../services/mixins/payrollMixin"
export default {
  mixins: [payrollMixin],
  data: () => ({
    department: "OP"
  }),
};
</script>